import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { API_BASE_URL } from '../../constants/config';



const createAxios = (config?: AxiosRequestConfig): AxiosInstance =>
  axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
    },
    ...config,
  });

export default createAxios;
